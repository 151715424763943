<template>
  <div>
    <!-- 列表-开始 -->
      <el-table
        class="index-list-table"
        :data="tableData"
        height="calc(100vh - 190px)">
        <el-table-column prop="name" label="考试人员"></el-table-column>
        <el-table-column prop="name" label="考试时间"></el-table-column>
        <el-table-column prop="name" label="考试结果"></el-table-column>
        <el-table-column prop="name" label="考试成绩"></el-table-column>
        <el-table-column prop="name" label="试卷名称"></el-table-column>
        <el-table-column label="操作" width="144px" fixed="right">
          <template slot-scope="scope">
            <el-button type="text" class="text-btn mr24" @click="_examinationDetail(scope.row)">详情</el-button>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页--开始 -->
      <el-pagination
        class="fx-row-end pt16"
        @size-change="_handleSizeChange"
        @current-change="_handleCurrentChange"
        :current-page="searchForm.page"
        :page-sizes="[10, 20, 30, 40, 50]"
        :page-size="searchForm.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="searchForm.total"
      >
      </el-pagination>
      <!-- 分页--结束 -->
      <!-- 列表-结束 -->
  </div>
</template>
<script>
export default {
  data () {
    return {
      tableData: [{ name: '111' }, { name: '111' }],
      searchForm: {
        page: 1,
        pageSize: 10,
        total: 1
      }
    }
  },
  methods: {
    _handleSizeChange (val) {
      this.searchForm.pageSize = val
      // this._getListPage()
    },
    _handleCurrentChange (val) {
      this.searchForm.page = val
      // this._getListPage()
    },
    _examinationDetail (row) {
      // 考试详情
    }
  }
}
</script>

<style></style>
